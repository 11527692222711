<template>
  <div>
    <div class="card">
      <div class="card-header">
        <a-menu
          mode="horizontal"
          :selected-keys="[$route.params.status || 'all']"
          @click="onStatusClick"
        >
          <a-menu-item key="all" title="All"> {{ $t('sales.all') }} </a-menu-item>
          <!-- <a-menu-item key="new" title="New">New</a-menu-item> -->
          <a-menu-item v-if="$store.getters['user/isDropship']" key="on-process" title="On Process"> {{ $t('sales.onComingCommission') }} </a-menu-item>
          <!-- <a-menu-item key="sent" title="Sent">Sent</a-menu-item>
          <a-menu-item key="delivered" title="Delivered">Delivered</a-menu-item> -->
          <a-menu-item v-if="isDistributor" key="waiting-payment" title="Waiting Payment" class="ml-auto"> {{ $t('sales.waitingPayment') }} </a-menu-item>
          <a-menu-item v-if="$store.getters['user/isDistributor']" key="on-process" title="On Process"> {{ $t('dashboard.onProcess') }} </a-menu-item>
          <a-menu-item key="completed" title="Completed"> {{ $t('sales.finishedOrdered') }} </a-menu-item>
          <!-- <a-menu-item key="cancel-request" title="Cancel Request" class="ml-auto"> {{ $t('sales.cancelRequest') }} </a-menu-item> -->
          <a-menu-item key="canceled" title="Canceled"> {{ $t('sales.canceled') }} </a-menu-item>
        </a-menu>

        <div class="row">
          <div class="col-md-4 mt-4">
            <a-form-item class="mb-0">
              <a-input-search
                placeholder="Order Number"
                :value="$route.query.q"
                @input="onSearchQueryChanged"
              />
            </a-form-item>
          </div>
          <div class="col-md-auto mt-4">
            <a-form-item class="mb-0">
              <a-range-picker :value="dateRangeValue" @change="changeDateRange"/>
            </a-form-item>
          </div>
        </div>
      </div>
      <div class="card-body">
        <a-table
          :loading="fetching"
          :columns="columns"
          :data-source="orders"
          :row-key="data => data.order_number"
          :pagination="false"
          class="order-table"
          :scroll="{
            x: 1024
          }"
        >
          <div slot="order_number" slot-scope="text, record" @click="rowClick(record)" style="cursor: pointer">
            <strong style="color: #4b7cf3;"> {{ record.order_number }} </strong>
            <div class="text-muted"> {{ $moment(record.created_at).format('DD MMM YYYY') }} </div>
          </div>
          <div slot="order_total" slot-scope="text, record" class="d-flex justify-content-between">
            <div>
              Rp
            </div>
            <div>
              {{ Number(record.total_transaction) | currency_2 }}
            </div>
          </div>
          <div slot="order_state" slot-scope="text, record">
            <OrderStatus type="ping" :status="record.order_state" />
          </div>
          <div slot="actions" slot-scope="text, record">
            <a-button class="mb-2" v-if="isDropship" @click="rowClick(record)"> Order Detail </a-button>
            <a-button
              class="mb-2"
              v-if="isDistributor && ['new', 'confirmed'].includes(record.order_state)"
              @click.prevent="() => (updateStatusFormVisibility = true, currentRecordToUpdateState = record)"
            >
              {{ $t('menu.order.send') }}
            </a-button>
            <a-button
              v-if="isDistributor && ['new', 'confirmed', 'delivered', 'completed', 'sent'].includes(record.order_state)"
              @click.prevent="previewLabel(record.order_number)"
            >
              Cetak Label
              {{ $t('menu.order.print_label') }}
            </a-button>
          </div>
          <div slot="customer_name" slot-scope="text, record">
            <strong> {{ record.ship_to }} </strong>
            <div class="text-muted"> {{ record.dest_city }} </div>
          </div>
          <div slot="commission" slot-scope="text, record" class="d-flex justify-content-between">
            <div>
              Rp
            </div>
            <div>
              {{ Number(record.commission) | currency_2 }}
            </div>
          </div>
        </a-table>

        <UpdateStatusForm
          v-if="updateStatusFormVisibility"
          @closed="(updateStatusFormVisibility = false, currentRecordToUpdateState = null)"
          @updated="handleUpdateStatus"
          :record="currentRecordToUpdateState"
        />

        <a-pagination
          v-if="total > 0"
          :total="total"
          :value="page"
          :page-size="pageSize"
          @change="page => $router.push({ query: { ...$route.query, page } })"
          class="mt-3 d-flex justify-content-end"
        />
      </div>
    </div>

    <router-view />
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import OrderStatus from '@/components/Order/OrderStatus'
import UpdateStatusForm from '@/components/Order/UpdateStatusForm'
import { mapGetters } from 'vuex'

const parseStatus = (status) => {
  switch (status) {
    // new, sent, delivered
    case 'on-process':
      return ['confirmed', 'on_process', 'ready_to_pickup', 'new', 'sent', 'delivered']

    case 'waiting-payment':
      return ['waiting_payment', 'draft']

    case 'cancel-request':
      return ['on_cancel_req', 'on_retour_req']

    case 'canceled':
      return ['canceled', 'retoured', 'payment_expired', 'payment_unfinish', 'payment_declined']

    case 'all':
    case undefined:
    case null:
      return undefined

    default:
      return status
  }
}

const parseStatusDistributor = (status) => {
  switch (status) {
    case 'all':
    case undefined:
    case null:
      return ['new', 'waiting_payment', 'sent', 'delivered', 'completed', 'payment_expired']

    case 'waiting-payment':
      return ['waiting_payment', 'draft']

    case 'on-process':
      return ['new', 'sent']

    case 'canceled':
      return ['canceled', 'retoured', 'payment_expired', 'payment_unfinish', 'payment_declined']

    default:
      return status
  }
}

const normalizePage = (value) => {
  if (isNaN(value)) {
    return 1
  }

  const page = Number(value)

  return page < 1 ? 1 : page
}

export default {
  name: 'OrderPage',
  components: {
    OrderStatus,
    UpdateStatusForm,
  },
  data() {
    return {
      fetching: true,
      orders: [],
      total: 0,
      dataColumns: [
        {
          title: this.$t('sales.orderNumberDate'),
          dataIndex: 'order_number',
          key: 'order_number',
          scopedSlots: { customRender: 'order_number' },
          class: 'align-middle',
          width: 200,
          roles: ['distributor', 'dropship'],
        },
        {
          title: this.$t('sales.customerNameAddress'),
          dataIndex: 'customer_name',
          key: 'customer_name',
          scopedSlots: { customRender: 'customer_name' },
          class: 'align-middle',
          width: 200,
          roles: ['distributor', 'dropship'],
        },
        {
          title: this.$t('sales.commission'),
          dataIndex: 'commission',
          key: 'commission',
          scopedSlots: { customRender: 'commission' },
          class: 'text-center',
          width: 150,
          roles: ['dropship'],
        },
        {
          title: this.$t('sales.totalShop'),
          dataIndex: 'order_total',
          key: 'order_total',
          scopedSlots: { customRender: 'order_total' },
          width: 150,
          class: 'text-center',
          roles: ['distributor', 'dropship'],
        },
        {
          title: this.$t('sales.status'),
          dataIndex: 'order_state',
          key: 'order_state',
          scopedSlots: { customRender: 'order_state' },
          width: 150,
          class: 'text-center',
          roles: ['distributor', 'dropship'],
        },
        {
          title: this.$t('sales.actions'),
          dataIndex: 'actions',
          key: 'actions',
          scopedSlots: { customRender: 'actions' },
          width: 150,
          class: 'text-center',
          roles: ['distributor', 'dropship'],
        },
      ],
      updateStatusFormVisibility: false,
      currentRecordToUpdateState: null,
      statusState: this.$route.params.status,
      query: this.$route.query,
      position: {
        x: 0,
        y: 0,
      },
    }
  },
  mounted() {
    this.fetchOrders()
  },
  computed: {
    ...mapGetters('user', ['isDropship', 'isDistributor']),
    dateRangeValue() {
      const { query } = this.$route

      return [
        query.from ? this.$moment(query.from) : null,
        query.to ? this.$moment(query.to) : null,
      ]
    },
    page() {
      return normalizePage(this.$route.query.page)
    },
    pageSize() {
      return Number(this.$route.query.pageSize) || 30
    },
    status() {
      return this.isDistributor ? parseStatusDistributor(this.statusState) : parseStatus(this.statusState)
    },
    columns () {
      const type = this.$store.getters['user/seller_type']
      switch (type) {
        case 'distributor':
          // eslint-disable-next-line
          const columnsDistributor = this.dataColumns.filter(item => item.roles.includes(type))
          return columnsDistributor

        case 'dropship':
          // eslint-disable-next-line
          const columnsDropship = this.dataColumns.filter(item => item.roles.includes(type))
          return columnsDropship

        default:
          return this.dataColumns
      }
    },
  },
  methods: {
    fetchOrders() {
      this.fetching = true

      this.$store.dispatch('sales/fetchOrders', {
        ...this.$route.query,
        order_state: this.status,
        page: this.page,
        limit: this.pageSize,
      })
        .then(
          (response) => {
            this.orders = response.data
            this.total = response.total_record
          },
        )
        .finally(() => {
          this.fetching = false
        })
    },
    onStatusClick({ key }) {
      this.statusState = key
    },
    onSearchQueryChanged: debounce(function (val) {
      this.$router.push({ query: { ...this.$route.query, q: val.target.value, page: 1 } })
    }, 300),
    changeDateRange([from, to]) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: 1,
          from: from ? from.format('YYYY-MM-DD') : undefined,
          to: to ? to.format('YYYY-MM-DD') : undefined,
        },
      })
    },
    rowClick(record) {
      // return (e) => {
      this.position.x = window.scrollX
      this.position.y = window.scrollY

      this.$router.push({
        name: 'order.view',
        params: {
          id: record.order_number,
        },
        query: {
          ...this.$route.query,
        },
      })
      // }
    },
    handleUpdateStatus () {
      this.fetchOrders()
    },
    previewLabel (order_number) {
      this.$router.push({
        path: `/order/label/${order_number}`,
      })
    },
  },
  watch: {
    '$route.query.q'(val) {
      this.fetchOrders()
    },
    '$route.query.from'(val) {
      this.fetchOrders()
    },
    '$route.query.to'(val) {
      this.fetchOrders()
    },
    '$route.query.page'(val) {
      this.fetchOrders()
    },
    '$route.query.pageSize'(val) {
      this.fetchOrders()
    },
    statusState(status) {
      this.fetchOrders()

      this.$router.push({
        params: {
          status,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss">
.v-top {
  vertical-align: top;
}
</style>
