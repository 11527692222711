<template>
  <span class="order-status" :class="type" :style="{ color }">
    <span v-if="type === 'ping'" class="dot ping-effect" :style="{ color }"></span>
    <span v-if="type === 'ping'" class="dot" :style="{ color }"></span>
    {{ displayText }}
  </span>
</template>

<script>
export default {
  name: 'OrderStatus',
  props: {
    status: {
      required: true,
    },
    type: {
      validate: (val) => ['badge', 'pills', 'ping'].includes(val),
      default() {
        return 'badge'
      },
    },
  },
  computed: {
    color() {
      if (['confirmed', 'on_process', 'ready_to_pickup'].includes(this.status)) {
        return '#ed8936'
      }

      if (['waiting_payment', 'draft'].includes(this.status)) {
        return '#a0aec0'
      }

      if (['on_cancel_req', 'on_retour_req'].includes(this.status)) {
        return '#f6e05e'
      }

      if (['canceled', 'retoured', 'payment_expired', 'payment_unfinish', 'payment_declined'].includes(this.status)) {
        return '#e53e3e'
      }

      if (['sent', 'delivered'].includes(this.status)) {
        return '#4299e1'
      }

      if (['completed'].includes(this.status)) {
        return '#38b2ac'
      }

      return '#81e6d9'
    },
    displayText() {
      const statuses = {
        new: 'New',
        confirmed: 'Confirmed',
        sent: 'Sent',
        delivered: 'Delivered',
        ready_to_pickup: 'Ready to pickup',
        packing: 'Packing',
        waiting_payment: 'Waiting payment',
        payment_expired: 'Payment is expired',
        canceled: 'Canceled',
        draft: 'Draft',
        completed: 'Completed',
        on_process: 'On process',
        on_cancel_req: 'Request for cancellation',
        on_retour_req: 'Request for return',
        payment_unfinish: 'Payment unfinish yet',
        payment_declined: 'Payment declined',
      }

      return Object.prototype.hasOwnProperty.call(statuses, this.status) ? statuses[this.status] : this.status
    },
  },
}
</script>

<style lang="scss" scoped>
.order-status {
  display: inline-flex;
}

.ping {
  color: inherit !important;
  padding-left: 1.25rem;
  position: relative;

  .dot {
    background-color: currentColor;
    position: absolute;
    left: 0;
    top: 5px;
    min-width: 10px;
    width: 10px;
    height: 10px;
    border-radius: 10rem;
    margin-right: .25rem;
    display: block;
    content: ''
  }

  .ping-effect {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
}

@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}
</style>
