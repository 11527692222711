<template>
  <a-modal
    :title="`Update Status #${record.order_number}`"
    :footer="false"
    :visible="visible"
    @cancel="close"
  >
    <div class="d-flex justify-content-center">
      <a-spin :spinning="fetching" />
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <div>
        <div class="text-muted"> Current Status </div>
        <div> {{ record.order_state }} </div>
      </div>
      <div>
        <a-icon type="arrow-right" />
      </div>
      <div>
        <div class="text-muted"> New Status </div>
        <div>
          <a-select
            style="width: 100px"
            v-model="statusUpdated"
          >
            <a-select-option v-for="(status, index) in availableStatus" :key="index" :value="status.value">
              {{ status.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>

    <div class="mt-3 d-flex flex-row-reverse">
      <div>
        <a-button
          type="primary"
          size="small"
          @click.prevent="handleUpdateStatus"
          :disabled="!statusUpdated"
        >
          Update Status
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { apiClientFastify } from '@/services/axios'
export default {
  data: function () {
    return {
      visible: true,
      fetching: false,
      availableStatus: [
        {
          label: 'sent',
          value: 'sent',
        },
        // {
        //   label: 'delivered',
        //   value: 'delivered',
        // },
      ],
      statusUpdated: null,
    }
  },
  props: {
    record: {
      type: Object,
    },
  },
  methods: {
    close () {
      this.visible = false
      setTimeout(() => this.$destroy(), 150)
    },
    async handleUpdateStatus () {
      try {
        this.fetching = true
        await apiClientFastify.post('/po/private/state', {
          order_state: this.statusUpdated,
          order_number: this.record.order_number,
        })
        this.$notification.success({
          message: 'Success update status',
        })
      } catch (err) {
        this.$notification.error({
          message: 'Oops! Something went wrong',
          description: err?.response?.data?.message || err?.response?.message || err?.message,
        })
      } finally {
        this.fetching = false
        this.$emit('updated')
        this.$emit('closed')
        // this.$nextTick(() => this.$destroy())
        // setTimeout(() => this.$destroy(), 150)
      }
    },
  },
  destroyed () {
    this.$emit('closed')
  },
}
</script>
